import React from 'react';
import Container from '@material-ui/core/Container';
import {
  Typography,
  Box,
  makeStyles,
  Card,
  CardContent,
  MenuList,
  MenuItem,
  Button,
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';

// Components
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const useStyles = makeStyles({
  title: {
    fontWeight: 900,
    textTransform: `uppercase`,
  },
  link: {
    boxShadow: `none`,
  },
});

function Artists({ pageContext, data, location }) {
  const { artist, layout } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const siteTitle = data.site.siteMetadata.title;

  const classes = useStyles();

  const artistHeader = `${artist} (${totalCount} song${
    totalCount === 1 ? '' : 's'
  })`;

  const allLink = layout === 'song' ? '/lyrics-by-artist' : '/chords-by-artist';

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={`${artist}'s songs in ChordSL.com`}
        description={`${artist}'s songs in ChordSL.com`}
      />
      <Container maxWidth="lg">
        <Box m={4}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={9} md={11}>
              <Typography variant="h5" component="h1" className={classes.title}>
                {artistHeader}
              </Typography>
            </Grid>
            <Grid item xs={3} md={1}>
              <Box ml={3}>
                <Button
                  to={allLink}
                  component={Link}
                  className={classes.link}
                  variant="outlined"
                >
                  All
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box m={2}>
            <Card>
              <CardContent>
                <MenuList>
                  {edges.map(({ node }) => {
                    const { slug } = node.fields;
                    const { title } = node.frontmatter;
                    return (
                      <MenuItem
                        key={slug}
                        component={Link}
                        to={slug}
                        className={classes.link}
                      >
                        {title}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
}

export default Artists;

export const pageQuery = graphql`
  query($artist: String, $layout: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 100
      sort: { fields: [frontmatter___title], order: DESC }
      filter: {
        frontmatter: { artist: { eq: $artist }, layout: { eq: $layout } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
